import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

function SignIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSignIn = () => {
    if (email === "Admin") navigate("/admin-dashboard");
    else navigate("/user-dashboard");
  };
  return (
    <div className="p-10 bg-gray-300 md:h-screen">
      <div className="rounded-lg bg-white md:h-full md:flex">
        <div
          className="flex justify-center items-center md:h-full md:w-full"
        >
          <img
            src="./p1.jpg"
            alt="Full screen image"
            className="object-cover rounded-lg md:h-full"
          />
        </div>
        <div
          className="bg-white h-full flex justify-center items-center p-10 md:w-[45%]"
          style={{ borderRadius: "0 10px 10px 0" }}
        >
          <div className="grid place-items-center">
            <div className="w-[10vh] h-[10vh] rounded-lg shadow-xl md:w-[10vw] md:h-[10vw]">
              <img src="./logo.png" alt="Logo image" />
            </div>
            <div className="border-2 border-solid mt-10 p-5 pt-10 pb-10">
              <div className="text-2xl font-bold mb-5">
                AffiliADS Account Manager
              </div>
              <p className="mb-2">Email</p>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mb-5 outline-none bg-gray-200 h-12 pl-2 pr-2"
                placeholder="Enter your email address"
              />
              <p className="mb-2">Password</p>
              <input
                type="text"
                className="w-full mb-8 outline-none bg-gray-200 h-12 pl-2 pr-2"
                placeholder="Enter your password"
              />
              <button
                className="w-full bg-[#3539FF] text-white h-12"
                onClick={handleSignIn}
              >
                Log In
              </button>
              <div className="text-right text-sm">Forgot your password?</div>
              <div className="text-right text-sm">
                Belum punya akun?{" "}
                <span className="font-bold cursor-pointer">
                  <Link to="/register">Daftar Disini</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;

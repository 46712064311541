import React from "react";
import { useNavigate, Link } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
  };
  return (
    <div className="w-full h-[800px]" style={{boxShadow: "rgba(3, 3, 3, 0.1) 10px 0px 0px"}}>
            <div className="text-2xl font-bold mt-7 mb-5 ml-5">
              Pending Transactions
            </div>
            <div className="bg-[#F9FCFF] p-5 ml-5 m-5" style={{boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px"}}>
              <table className="w-full">
                <thead className="mb-5">
                  <tr className="pb-5 mb-5">
                    <td className="font-bold">ORDER ID</td>
                    <td className="font-bold">DATE</td>
                    <td className="font-bold">CLIENT NAME</td>
                    <td className="font-bold">EMAIL</td>
                    <td className="font-bold">WHATSAPP GROUP</td>
                    <td className="font-bold">AMOUNT</td>
                    <td className="font-bold">STATUS</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>20240318102</td>
                    <td>18 Maret 2024</td>
                    <td>John Doe</td>
                    <td>johndoe@gmail.com</td>
                    <td>johnstore x steffi x AA</td>
                    <td>Rp. 100,000,000</td>
                    <td>
                      <button className="bg-[#FF9500] text-white w-full">
                        Pending
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>20240318102</td>
                    <td>18 Maret 2024</td>
                    <td>John Doe</td>
                    <td>johndoe@gmail.com</td>
                    <td>johnstore x steffi x AA</td>
                    <td>Rp. 100,000,000</td>
                    <td>
                      <button className="bg-[#FF9500] text-white w-full">
                        Pending
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>20240318102</td>
                    <td>18 Maret 2024</td>
                    <td>John Doe</td>
                    <td>johndoe@gmail.com</td>
                    <td>johnstore x steffi x AA</td>
                    <td>Rp. 100,000,000</td>
                    <td>
                      <button className="bg-blue-500 text-white w-full">
                        Approve
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>20240318102</td>
                    <td>18 Maret 2024</td>
                    <td>John Doe</td>
                    <td>johndoe@gmail.com</td>
                    <td>johnstore x steffi x AA</td>
                    <td>Rp. 100,000,000</td>
                    <td>
                      <button className="bg-blue-500 text-white w-full">
                        Approve
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-2xl font-bold mt-7 mb-5 ml-5">
              Client List
            </div>
            <div className="bg-[#F9FCFF] p-5 ml-5 m-5" style={{boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px"}}>
              <table className="w-full">
                <thead className="mb-5">
                  <tr className="pb-5 mb-5">
                    <td className="font-bold">Nama Pemilik / Penanggung Jawab</td>
                    <td className="font-bold">Email</td>
                    <td className="font-bold">WhatsApp Group</td>
                    <td className="font-bold">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>johndoe@gmail.com</td>
                    <td>johnstore x steffi x AA</td>
                    <td>
                      <button className="bg-[#21338E] text-white w-[50%]">
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>johndoe@gmail.com</td>
                    <td>johnstore x steffi x AA</td>
                    <td>
                      <button className="bg-[#21338E] text-white w-[50%]">
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  );
}

export default Dashboard;

import React from "react";
import { useNavigate, Link } from "react-router-dom";

function AdminAndSalesList(props) {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
  };
  return (
    <div className="w-full">
      <div className="text-2xl font-bold mt-7 mb-5 ml-5">Admin List</div>
      <div
        className="bg-[#F9FCFF] p-5 ml-5 m-5"
        style={{ boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px" }}
      >
        <table className="w-full">
          <thead className="mb-5">
            <tr className="pb-5 mb-5">
              <td className="font-bold">Admin Name</td>
              <td className="font-bold">Email</td>
              <td className="font-bold w-[50%]">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={"John Doe"}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={"johndoe@gmail.com"}
                />
              </td>
              <td>
                <div className="flex">
                  <button className="bg-[#21338E] text-white mr-2 w-[19%]">
                    Edit
                  </button>
                  <button className="bg-[#21338E] text-white mr-2 w-[19%]">
                    Save
                  </button>
                  <button className="bg-[#FF9500] text-white mr-2 w-[39%]">
                    Change Password
                  </button>
                  <button className="bg-red-500 text-white mr-2 w-[19%]">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={"John Doe"}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={"johndoe@gmail.com"}
                />
              </td>
              <td>
                <div className="flex">
                  <button className="bg-[#21338E] text-white mr-2 w-[19%]">
                    Edit
                  </button>
                  <button className="bg-[#21338E] text-white mr-2 w-[19%]">
                    Save
                  </button>
                  <button className="bg-[#FF9500] text-white mr-2 w-[39%]">
                    Change Password
                  </button>
                  <button className="bg-red-500 text-white mr-2 w-[19%]">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={""}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={""}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full flex">
          <button className="bg-[#488FC9] text-white mr-2 h-10 ml-auto w-[150px]">
            Add Admin
          </button>
        </div>
      </div>
      <div className="text-2xl font-bold mt-7 mb-5 ml-5">Sales List</div>
      <div
        className="bg-[#F9FCFF] p-5 ml-5 m-5"
        style={{ boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px" }}
      >
        <table className="w-full">
          <thead className="mb-5">
            <tr className="pb-5 mb-5">
              <td className="font-bold">Sales Name</td>
              <td className="font-bold w-[50%]">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={"John Doe"}
                />
              </td>
              <td>
                <div className="flex">
                  <button className="bg-[#21338E] text-white mr-2 w-[29%]">
                    Edit
                  </button>
                  <button className="bg-[#21338E] text-white mr-2 w-[29%]">
                    Save
                  </button>
                  <button className="bg-red-500 text-white mr-2 w-[39%]">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={"John Doe"}
                />
              </td>
              <td>
                <div className="flex">
                  <button className="bg-[#21338E] text-white mr-2 w-[29%] ml-auto">
                    Edit
                  </button>
                  <button className="bg-[#21338E] text-white mr-2 w-[29%]">
                    Save
                  </button>
                  <button className="bg-red-500 text-white mr-2 w-[39%] ml-auto">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                  value={""}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full flex">
          <button className="bg-[#488FC9] text-white mr-2 h-10 ml-auto w-[150px]">
            Add Sales
          </button>
        </div>
      </div>

      <div className="flex">
        <button className="bg-[#21338E] text-white w-[150px] h-10 m-10 ml-auto">
          Back
        </button>
      </div>
    </div>
  );
}

export default AdminAndSalesList;

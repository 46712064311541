import React from "react";
import { useNavigate, Link } from "react-router-dom";

function TopUp() {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
  };

  const handleBack = () => {
    navigate("/user-dashboard");
  }
  return (
    <div className="p-3 bg-gray-300 md:h-screen">
      <div className="rounded-xl bg-[#EEEEEE] md:h-full p-0 overflow-y-auto">
        <div className="flex justify-between items-center pt-2 pl-5 pr-5 shadow-md shadow-bottom bg-white rounded-tl-lg rounded-tr-lg">
          <img
            src="./logo.png"
            className="w-[100px] h-[100px] mr-5"
            alt="Logo image"
          />
          <div className="text-lg sm:flex">
            <div className="font-bold mr-5">
              Email: <u>user@example.com</u>
            </div>
            <div onClick={handleLogout} className="cursor-pointer">
              LOG OUT
            </div>
          </div>
        </div>
        <div className="p-10">
          <div className="bg-white rounded-lg w-full p-5 mt-5">
            <div className="mb-3 font-bold">AD Account - 1</div>
            <div className="mb-3 text-gray-500 font-bold">AD Account</div>
            <select className="w-full mb-3 md:w-full outline-none h-9 border-2 border-solid border-gray-300 rounded-lg">
              <option>AAAAAAAAAAA</option>
              <option>AAAAAAAAAAA</option>
              <option>AAAAAAAAAAA</option>
            </select>
            <div className="font-bold mb-3">Nominal Topup</div>
            <div className="border-2 border-solid rounded-lg flex items-center pl-3 pr-3 w-full p-1 mb-5">
              <input
                type="text"
                className="outline-none w-full"
                placeholder="Rp. 100,000,000"
              />
            </div>

            <div className="text-white bg-blue-500 border-2 font-bold border-solid p-2 pr-10 pl-10 mr-5 mt-5 w-full text-center cursor-pointer text-xl">
              {" "}
              ⊕ Tambah Akun Lain
            </div>
          </div>
          <div className="bg-white rounded-lg w-full p-5 mt-5">
            Rincian dan Metode Pembayaran
          </div>
          <div className="bg-white rounded-lg w-full p-5 mt-5">
            <div className="flex justify-between mb-3">
              <div>Total Topup</div>
              <div className="font-bold">Rp O</div>
            </div>
            <div className="flex justify-between mb-3">
              <div>Total yang harus dibayar</div>
              <div className="fit-content bg-gray-300 text-sm p-1">Rp O</div>
            </div>
            <div>Transfer Virtual Account</div>
          </div>
        </div>

        <div className="flex">
          <button className="bg-[#21338E] text-white w-[150px] h-10 m-10 ml-auto" onClick={handleBack}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default TopUp;

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const transactions = [
  {
    id: "20240318102",
    date: "18 Maret 2024",
    clientName: "John Doe",
    email: "johndoe@gmail.com",
    whatsAppGroup: "johnstore x steffi x AA",
    amount: "Rp. 100,000,000",
    currentStatus: false,
  },
  {
    id: "20240318103",
    date: "18 Maret 2024",
    clientName: "John Doe",
    email: "johndoe@gmail.com",
    whatsAppGroup: "johnstore x steffi x AA",
    amount: "Rp. 100,000,000",
    currentStatus: false,
  },
  {
    id: "20240318104",
    date: "18 Maret 2024",
    clientName: "John Doe",
    email: "johndoe@gmail.com",
    whatsAppGroup: "johnstore x steffi x AA",
    amount: "Rp. 100,000,000",
    currentStatus: true,
  },
  {
    id: "20240318105",
    date: "18 Maret 2024",
    clientName: "John Doe",
    email: "johndoe@gmail.com",
    whatsAppGroup: "johnstore x steffi x AA",
    amount: "Rp. 100,000,000",
    currentStatus: true,
  },
];

function Transactions() {
  const navigate = useNavigate();

  const [transactionList, setTransactionList] = useState(transactions);

  const handleLogout = () => {
    navigate("/");
  };

  const changeStatus = async(transaction) => {
    const updatedList = [...transactionList];
    const index = transactionList.findIndex(item => item.id === transaction.id);

    updatedList[index].currentStatus = !updatedList[index].currentStatus;
    setTransactionList(updatedList);
  }
  return (
    <div
      className="w-full h-[800px]"
      style={{ boxShadow: "rgba(3, 3, 3, 0.1) 10px 0px 0px" }}
    >
      <div className="text-2xl font-bold mt-7 mb-5 ml-5">
        Pending Transactions
      </div>
      <div
        className="bg-[#F9FCFF] p-5 ml-5 m-5"
        style={{ boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px" }}
      >
        <table className="w-full">
          <thead className="mb-5">
            <tr className="pb-5 mb-5">
              <td className="font-bold">ORDER ID</td>
              <td className="font-bold">DATE</td>
              <td className="font-bold">CLIENT NAME</td>
              <td className="font-bold">EMAIL</td>
              <td className="font-bold">WHATSAPP GROUP</td>
              <td className="font-bold">AMOUNT</td>
              <td className="font-bold">Pending/Approved</td>
            </tr>
          </thead>
          <tbody>
            {transactionList.map((transaction, index) => {
              return <tr className="p-2" key={index}>
                <td className="text-sm">{transaction.id}</td>
                <td className="text-sm">{transaction.date}</td>
                <td className="text-sm">{transaction.clientName}</td>
                <td className="text-sm">{transaction.email}</td>
                <td className="text-sm">{transaction.whatsAppGroup}</td>
                <td className="text-sm">{transaction.amount}</td>
                <td className="text-sm border-black">
                  <div
                    className="w-[130px] h-5 border-2 border-solid rounded-full flex cursor-pointer"
                    style={{ padding: "2px" }}
                    onClick={() => changeStatus(transaction)}
                  >
                    {transaction.currentStatus? <div className="w-[50%] rounded-full bg-[#488FC9] ml-auto"></div> :
                    <div className="w-[50%] rounded-full bg-[#FF9500]" ></div>}
                  </div>
                </td>
              </tr>;
            })}
            {/* <tr classname="p-2">
              <td className="text-sm">20240318102</td>
              <td className="text-sm">18 Maret 2024</td>
              <td className="text-sm">John Doe</td>
              <td className="text-sm">johndoe@gmail.com</td>
              <td className="text-sm">johnstore x steffi x AA</td>
              <td className="text-sm">Rp. 100,000,000</td>
              <td className="text-sm border-black">
                <div
                  className="w-[130px] h-5 border-2 border-solid rounded-full flex"
                  style={{ padding: "2px" }}
                >
                  <div className="w-[50%] rounded-full bg-[#FF9500]"></div>
                </div>
              </td>
            </tr>
            <tr classname="p-2">
              <td className="text-sm">20240318102</td>
              <td className="text-sm">18 Maret 2024</td>
              <td className="text-sm">John Doe</td>
              <td className="text-sm">johndoe@gmail.com</td>
              <td className="text-sm">johnstore x steffi x AA</td>
              <td className="text-sm">Rp. 100,000,000</td>
              <td className="text-sm">
                <div
                  className="w-[130px] h-5 border-2 border-solid rounded-full flex"
                  style={{ padding: "2px" }}
                >
                  <div className="w-[50%] rounded-full bg-[#FF9500]"></div>
                </div>
              </td>
            </tr>
            <tr classname="p-2">
              <td className="text-sm">20240318102</td>
              <td className="text-sm">18 Maret 2024</td>
              <td className="text-sm">John Doe</td>
              <td className="text-sm">johndoe@gmail.com</td>
              <td className="text-sm">johnstore x steffi x AA</td>
              <td className="text-sm">Rp. 100,000,000</td>
              <td className="text-sm">
                <div
                  className="w-[130px] h-5 border-2 border-solid rounded-full flex"
                  style={{ padding: "2px" }}
                >
                  <div className="w-[50%] rounded-full bg-[#488FC9] ml-auto"></div>
                </div>
              </td>
            </tr>
            <tr classname="p-2">
              <td className="text-sm">20240318102</td>
              <td className="text-sm">18 Maret 2024</td>
              <td className="text-sm">John Doe</td>
              <td className="text-sm">johndoe@gmail.com</td>
              <td className="text-sm">johnstore x steffi x AA</td>
              <td className="text-sm">Rp. 100,000,000</td>
              <td className="text-sm">
                <div
                  className="w-[130px] h-5 border-2 border-solid rounded-full flex"
                  style={{ padding: "2px" }}
                >
                  <div className="w-[50%] rounded-full bg-[#488FC9] ml-auto"></div>
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Transactions;

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import Client from "./Client";
import ClientView from "./ClientView";
import Transactions from "./Transaction";
import AdminAndSalesList from "./AdminAndSalesList";

function AdminDashboard() {
  const navigate = useNavigate();

  const [panel, setPanel] = useState("dashboard");
  const [flag, setFlag] = useState(false);

  const handleLogout = () => {
    navigate("/");
  };

  const changePanel = (panel) => {
    setPanel(panel);
  };
  return (
    <div className="p-8 bg-[#EEEEEE] md:h-screen">
      <div
        className="absolute top-[140px] bg-white border-2 border-solid border-black rounded-full cursor-pointer lg:hidden"
        style={{ left: "-20px" }}
        onClick={() => setFlag(!flag)}
      >
        <img src="./arrow.png" alt="Arrow image" className="h-10" />
      </div>
      <div>
        {flag && <div className="w-[200px] lg:hidden h-[80%] pt-10 bg-[#EEEEEE] absolute top-[140px] left-8">
          <div
            className="p-3 text-xl cursor-pointer hover:bg-white"
            onClick={() => changePanel("dashboard")}
          >
            Dashboard
          </div>
          <div
            className="p-3 text-xl cursor-pointer hover:bg-white"
            onClick={() => changePanel("clients")}
          >
            Clients
          </div>
          <div
            className="p-3 text-xl cursor-pointer hover:bg-white"
            onClick={() => changePanel("transactions")}
          >
            Transactions
          </div>
          <div
            className="p-3 text-xl cursor-pointer hover:bg-white"
            onClick={() => changePanel("reports")}
          >
            Reports
          </div>
          <div
            className="p-3 text-xl cursor-pointer hover:bg-white"
            onClick={() => changePanel("adminsaleslist")}
          >
            Admin & Sales List
          </div>
        </div>}
      </div>
      <div className="rounded-xl bg-[#EEEEEE] md:h-full p-0 overflow-y-auto h-full">
        <div className="flex justify-between border-2 border-bottom items-center p-5 pl-5 pr-5 shadow-lg shadow-bottom bg-white rounded-tl-lg rounded-tr-lg z-10">
          <div className="flex items-center">
            <svg
              viewBox="0 0 512 512"
              className="mr-2"
              style={{
                width: "24px",
                height: "24px",
                overflow: "visible",
                opacity: "1",
                zIndex: "1",
                fill: "rgb(3, 3, 3)",
              }}
            >
              <path d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z"></path>
            </svg>
            <div className="text-2xl font-bold">Admin Name</div>
          </div>
          <div className="hidden text-lg lg:flex items-center">
            <div className="whitespace-nowrap mr-2">Welcome, Admin!</div>
            <div className="border-2 border-solid rounded-full flex items-center pl-3 pr-3 w-full sm:w-[400px] p-1 mr-5">
              <input
                type="text"
                className="outline-none w-full"
                placeholder="Search Client"
              />
            </div>
            <div className="flex items-center ml-auto pt-3 sm:pt-0">
              <div className="mr-5">
                <svg
                  className="w-[21px] h-[21px] overflow-visible opacity-100 z-10"
                  viewBox="0 0 448 512"
                  style={{ fill: "rgb(3, 3, 3)" }}
                >
                  <path d="M256 32V49.88C328.5 61.39 384 124.2 384 200V233.4C384 278.8 399.5 322.9 427.8 358.4L442.7 377C448.5 384.2 449.6 394.1 445.6 402.4C441.6 410.7 433.2 416 424 416H24C14.77 416 6.365 410.7 2.369 402.4C-1.628 394.1-.504 384.2 5.26 377L20.17 358.4C48.54 322.9 64 278.8 64 233.4V200C64 124.2 119.5 61.39 192 49.88V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32V32zM216 96C158.6 96 112 142.6 112 200V233.4C112 281.3 98.12 328 72.31 368H375.7C349.9 328 336 281.3 336 233.4V200C336 142.6 289.4 96 232 96H216zM288 448C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288z"></path>
                </svg>
              </div>
              <button
                className="bg-[#3539FF] text-white h-10 p-1 pl-3 pr-3"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
          <div className="lg:hidden flex">
          <div className="whitespace-nowrap mr-2 text-lg hidden sm:hidden">Welcome, Admin!</div>
            <img className="h-7 mr-3 cursor-pointer" src="./1.png" alt="Search Icon" />
            <svg
                  className="w-[21px] h-7 overflow-visible opacity-100 z-10 mr-3  cursor-pointer"
                  viewBox="0 0 448 512"
                  style={{ fill: "rgb(3, 3, 3)" }}
                >
                  <path d="M256 32V49.88C328.5 61.39 384 124.2 384 200V233.4C384 278.8 399.5 322.9 427.8 358.4L442.7 377C448.5 384.2 449.6 394.1 445.6 402.4C441.6 410.7 433.2 416 424 416H24C14.77 416 6.365 410.7 2.369 402.4C-1.628 394.1-.504 384.2 5.26 377L20.17 358.4C48.54 322.9 64 278.8 64 233.4V200C64 124.2 119.5 61.39 192 49.88V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32V32zM216 96C158.6 96 112 142.6 112 200V233.4C112 281.3 98.12 328 72.31 368H375.7C349.9 328 336 281.3 336 233.4V200C336 142.6 289.4 96 232 96H216zM288 448C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288z"></path>
                </svg>
            <img className="h-6 cursor-pointer" src="./logout.png" alt="Logout Icon" />
          </div>
        </div>
        <div className="flex bg-[#FDFDFD] h-100%">
          <div className="w-[20%] hidden lg:block pt-10 bg-[#EEEEEE]">
            <div
              className="p-3 text-xl cursor-pointer hover:bg-white"
              onClick={() => changePanel("dashboard")}
            >
              Dashboard
            </div>
            <div
              className="p-3 text-xl cursor-pointer hover:bg-white"
              onClick={() => changePanel("clients")}
            >
              Clients
            </div>
            <div
              className="p-3 text-xl cursor-pointer hover:bg-white"
              onClick={() => changePanel("transactions")}
            >
              Transactions
            </div>
            <div
              className="p-3 text-xl cursor-pointer hover:bg-white"
              onClick={() => changePanel("reports")}
            >
              Reports
            </div>
            <div
              className="p-3 text-xl cursor-pointer hover:bg-white"
              onClick={() => changePanel("adminsaleslist")}
            >
              Admin & Sales List
            </div>
          </div>
          {panel === "dashboard" && <Dashboard />}
          {panel === "clients" && <Client changePanel={changePanel} />}
          {panel === "clientsView" && <ClientView changePanel={changePanel}/>}
          {panel === "transactions" && <Transactions />}
          {panel === "adminsaleslist" && <AdminAndSalesList />}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;

import React from "react";
import { useNavigate, Link } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/');
  }
  return (
    <div className="p-3 bg-gray-300 md:h-screen">
      <div className="rounded-xl bg-[#EEEEEE] md:h-full p-0 overflow-y-auto">
        <div className="flex items-center pt-2 pl-5 pr-5 shadow-md shadow-bottom bg-white rounded-tl-lg rounded-tr-lg">
          <img
            src="./logo.png"
            className="w-[100px] h-[100px] mr-5"
            alt="Logo image"
          />
          <div className="text-2xl font-bold tracking-wide">
            SELAMAT DATANG DI AFFILIADS. APABILA ANDA MEMILIKI LEBIH DARI 1
            PRODUK, CUKUP MENGISI FORM PENDAFTARAN INI SEKALI SAJA.
          </div>
        </div>
        <div className="bg-[#F9FCFF] m-5 mt-10 p-5">
          <div className="text-2xl font-bold mb-5">Buat Akun Baru</div>
          <div className="md:flex">
            <div className="w-full mr-5">
              <div className="mb-2">Nama Pemilik/Penanggung Jawab</div>
              <input
                type="text"
                className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                placeholder="Enter ..."
              />
            </div>
            <div className="w-full">
              <div className="mb-2">
                Email Login dan Penerima Kontrak (harus 1 email yang sama)
              </div>
              <input
                type="text"
                className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                placeholder="Enter ..."
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full mr-5">
              <div className="mb-2">Password</div>
              <input
                type="password"
                className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                placeholder="Enter ..."
              />
            </div>
            <div className="w-full">
              <div className="mb-2">Konfirmasi Password</div>
              <input
                type="password"
                className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                placeholder="Enter ..."
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="flex w-full mr-5 mb-5">
              <div className="w-[60%] mr-3">
                <div className="mb-2">Jenis Badan Usaha</div>
                <select className="w-full outline-none h-9 border-2 border-solid border-black rounded-lg">
                  <option>AAAAAAAAAAA</option>
                  <option>AAAAAAAAAAA</option>
                  <option>AAAAAAAAAAA</option>
                </select>
              </div>
              <div className="w-[40%]">
                <div className="mb-2">Tipe Kegiatan Bisnis</div>
                <div className="flex h-9 items-center">
                  <div className="mr-3">
                    <input
                      type="radio"
                      id="option1"
                      name="options"
                      value="option1"
                      className="mr-2"
                    />
                    <label htmlFor="option1">Pemilik Brand</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="option2"
                      name="options"
                      value="option2"
                      className="mr-2"
                    />
                    <label htmlFor="option2">Reseller</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2">Upload NPWP/KTP</div>
              <button className="w-full bg-black text-white h-8 rounded-lg">
                Upload
              </button>
            </div>
          </div>
          <div className="md:flex">
            <div className="flex w-full mr-5 mb-3">
              <div className="w-[60%] mr-3">
                <div className="mb-2">Nama di NPWP/KTP</div>
                <input
                  type="text"
                  className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                  placeholder="Enter ..."
                />
              </div>
              <div className="w-[40%]">
                <div className="mb-2">Nomor NPWP/KTP</div>
                <input
                  type="text"
                  className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                  placeholder="Enter ..."
                />
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2">Alamat di NPWP / KTP</div>
              <input
                type="text"
                className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                placeholder="Enter ..."
              />
            </div>
          </div>
          <div className="md:flex">
            <div className="flex w-full mr-5 mb-3">
              <div className="w-[60%] mr-3">
                <div className="mb-2">Nama Produk</div>
                <input
                  type="text"
                  className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                  placeholder="Enter ..."
                />
              </div>
              <div className="w-[40%]">
                <div className="mb-2">Nomor WhatsApp</div>
                <input
                  type="text"
                  className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                  placeholder="Enter ..."
                />
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-[50%] mr-3">
                <div className="mb-2">Nama Grup WhatsApp</div>
                <input
                  type="text"
                  className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                  placeholder="Enter ..."
                />
              </div>
              <div className="w-[50%]">
                <div className="mb-2">Jenis Produk</div>
                <input
                  type="text"
                  className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                  placeholder="Enter ..."
                />
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="w-full mr-5">
              <div className="mb-2">Website/ URL Produk</div>
              <input
                type="text"
                className="w-full mb-4 outline-none bg-gray-200 h-8 pl-2 pr-2"
                placeholder="Enter ..."
              />
            </div>
            <div className="w-full">
              <div className="mb-2">
                Upload BPOM jika Anda menjual produk herbal, kesehatan, F&B.
              </div>
              <button className="w-full bg-black text-white h-8 rounded-lg">
                Upload
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-2">
            <div className="w-full">
              <center>
                <div className="mb-2 md:text-center w-full text-left">
                  Nama Sales
                </div>
                <select className="w-full md:w-[30vw] outline-none h-9 border-2 border-solid border-gray-300 rounded-lg">
                  <option>AAAAAAAAAAA</option>
                  <option>AAAAAAAAAAA</option>
                  <option>AAAAAAAAAAA</option>
                </select>
              </center>
            </div>
          </div>
        </div>
        <div className="flex justify-between shadow-md mb-0 shadow-bottom bg-white rounded-bl-lg rounded-br-lg mt-7 bg-white">
          <div className="ml-auto">
            <button className="bg-[#3539FF] text-white h-8 pl-4 pr-4 m-2" onClick={handleRegister}>
              Submit
            </button>
            <button className="bg-[#3539FF] text-white h-8 pl-4 pr-4 m-5">
            <Link to="/">Cancel</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

import React from "react";
import { useNavigate, Link } from "react-router-dom";

function Client(props) {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
  };
  return (
    <div className="w-full">
      <div className="text-2xl font-bold mt-7 mb-5 ml-5">Client List</div>
      <div
        className="bg-[#F9FCFF] p-5 ml-5 m-5"
        style={{ boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px" }}
      >
        <table className="w-full">
          <thead className="mb-5">
            <tr className="pb-5 mb-5">
              <td className="font-bold">Nama Pemilik / Penanggung Jawab</td>
              <td className="font-bold">Email</td>
              <td className="font-bold">WhatsApp Group</td>
              <td className="font-bold">Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>John Doe</td>
              <td>johndoe@gmail.com</td>
              <td>johnstore x steffi x AA</td>
              <td>
                <button className="bg-[#21338E] text-white w-[50%]" onClick={() => props.changePanel("clientsView")}>
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>John Doe</td>
              <td>johndoe@gmail.com</td>
              <td>johnstore x steffi x AA</td>
              <td>
                <button className="bg-[#21338E] text-white w-[50%]" onClick={() => props.changePanel("clientsView")}>
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Client;

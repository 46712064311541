import React from "react";
import { useNavigate, Link } from "react-router-dom";

function UserDashboard() {
  const navigate = useNavigate();

  const toTopUp = () => {
    navigate("/topup");
  };

  const handleLogout = () => {
    navigate("/");
  };
  return (
    <div className="p-3 bg-gray-300 md:h-screen">
      <div className="rounded-xl bg-[#EEEEEE] md:h-full p-0 overflow-y-auto h-fit-content">
        <div className="flex justify-between items-center pl-5 pr-5 shadow-md shadow-bottom bg-white rounded-tl-lg rounded-tr-lg">
          <img
            src="./logo.png"
            className="w-[100px] h-[100px] mr-5"
            alt="Logo image"
          />
          <div className="text-lg sm:flex">
            <div className="font-bold mr-5 md:whitespace-nowrap sm:text-right">
              Email: <u>user@example.com</u>
            </div>
            <div className="sm:text-right cursor-pointer" onClick={handleLogout}>LOG OUT</div>
          </div>
        </div>
        <div className="p-9">
          <div
            className="bg-cover bg-center font-bold text-xl sm:text-3xl md:text-4xl text-white rounded-lg p-5"
            style={{ backgroundImage: "url('./p1.jpg')" }}
          >
            ANNOUNCEMENT: TOP UP WILL BE PAUSED DURING HOLIDAY FROM 8 APRIL - 9
            APRIL 2024. WE WILL RESUME ON 10 MARCH 2024 (THIS IS AN IMAGE)
          </div>
          <div className="text-gray-500 mt-5 text-xl">
            <div>Top-up Saldo Ad Account SnackVideo ANda.</div>
            <div>
              Akses informasi lengkap tentang transaksi pengisian ulang saldo
              Anda
            </div>
          </div>
          <div className="mt-5 lg:flex">
            <div className="bg-white rounded-lg w-full lg:w-[45%] p-2 flex mr-5 mb-5 lg:mb-0">
              <div
                className="text-3xl text-bold mt-0 flex ml-3"
                style={{ fontWeight: "900" }}
              >
                ⇄
              </div>
              <div className="ml-5 mt-1">
                <div className="text-md md:text-xl font-bold">
                  Permintaan Top-Up Terakhir
                </div>
                <div className="xl:flex md:block sm:flex mt-3">
                  <div className="text-2xl sm:text-3xl md:text-5xl font-medium mr-10 md:-20 whitespace-nowrap">
                    IDR 50,000,000
                  </div>
                  <div className="text-sm sm:p-2">
                    Tanggal Permintaan: <br /> 18 Maret 2024, 17:00
                  </div>
                </div>
                <div className="sm:flex lg:items-center justify-center w-full mt-3">
                  <div>Status Terakhir:</div>
                  <span
                    className="text-[#ff9500ff] text-2xl sm:ml-10"
                    style={{ fontWeight: "700" }}
                  >
                    PENDING
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg w-full lg:w-[55%] p-5">
              <div className="text-xl font-bold mb-3">Daftar ID AD Account</div>
              <div className="container mx-auto">
                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6">
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                  <div className="">1234567890</div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg w-full p-5 mt-5">
            <div className="text-xl font-bold mb-3">Transaction History</div>
            <div className="md:flex justify-between">
              <div className="border-2 border-solid rounded-lg flex items-center p-2 pl-3 pr-3 w-full md:w-[30%] mb-3 h-fit-content" style={{height: "fit-content"}}>
                <img src="./1.png" className="h-5 mr-2" alt="Search Icon"/>
                <input type="text" className="outline-none w-full" placeholder="Cari Berdasarkan ID AD Account atau Order ID" />
              </div>
              <div className="sm:flex sm:ml-3">
                <div className="text-gray-500 border-2 border-solid rounded-full w-full sm:w-fit-content p-2 pr-10 pl-10 mr-5 cursor-pointer text-center mb-3 max-h-fit-content"  style={{height: "fit-content"}}>Filter</div>
                <div className="text-gray-500 border-2 border-solid rounded-full w-full p-2 pr-10 pl-10 mr-5 cursor-pointer text-center mb-3 max-h-fit-content" style={{height: "fit-content"}}>Export</div>
                <div className="flex items-center justify-center text-white bg-blue-500 border-2 border-solid rounded-full p-2 pr-10 pl-10 mr-5 cursor-pointer text-center mb-3 whitespace-nowrap" style={{height: "fit-content"}}> <img src="./plus.svg" alt="Add Icon" className="h-6 mr-1"/> <span>Top Up</span></div>
              </div>
            </div>
            <table className="w-full mt-5">
              <thead>
                <tr className="pb-5">
                  <td className="font-bold pb-2">Order ID</td>
                  <td className="font-bold">Tanggal</td>
                  <td className="font-bold">Waktu</td>
                  <td className="font-bold">Amount</td>
                  <td className="font-bold">Status</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>20240318102</td>
                  <td>18 Maret 2024</td>
                  <td>10:00</td>
                  <td>IDR 10,000,000</td>
                  <td>Success</td>
                </tr>
                <tr>
                  <td>20240317008</td>
                  <td>17 Maret 2024</td>
                  <td>10:00</td>
                  <td>IDR 15,000,000</td>
                  <td>Pending</td>
                </tr>
                <tr>
                  <td>20240317002</td>
                  <td>17 Maret 2024</td>
                  <td>10:00</td>
                  <td>IDR 5,000,000</td>
                  <td>Success</td>
                </tr>
              </tbody>
            </table>
            <center>
            <div className="flex items-center justify-center text-white bg-blue-500 border-2 border-solid rounded-full p-3 pr-10 pl-10 mr-5 mt-5 cursor-pointer w-full sm:w-[400px]" onClick={toTopUp}> <img src="./plus.svg" alt="Add Icon" className="h-6 mr-1"/> <span>REQUEST TOP UP BARU</span></div>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;

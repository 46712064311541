import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './components/SignIn';
import SignUp from './components/Signup';
import UserDashboard from './components/UserDashboard';
import TopUp from './components/TopUp';
import AdminDashboard from './components/Admin/AdminDashboard';
import './App.css';


function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/user-dashboard" element={<UserDashboard />} />
          <Route path="/topup" element={<TopUp />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
        </Routes>
    </Router>
  );
}

export default App;

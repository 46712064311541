import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const accountID = [
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
  "1234567890",
]
function ClientView(props) {
  const navigate = useNavigate();
  const [accountList, setAccountList] = useState(accountID);

  const handleBack = () => {
    props.changePanel("clients");
  };
  return (
    <div className="w-full p-5">
      <div className="text-xl font-bold mt-2 mb-5 ml-5">
        John Doe (Nama Group WhatsApp)
      </div>
      <div
        className="bg-[#F9FCFF] p-5 w-full"
        style={{ boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px" }}
      >
        <div className="lg:flex">
          <div className="w-full lg:w-[50%] mb-2 mr-2">
            <div className="w-[50%]">
              <div className="whitespace-nowrap">
                Nama Pemilik / Penanggung Jawab
              </div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"johndoe"}
              />
            </div>
          </div>
          <div className="w-full lg:w-[50%] mb-2">
            <div>Email Login</div>
            <input
              type="text"
              value={"johndoe@example.com"}
              className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
            />
          </div>
        </div>
        <div className="lg:flex">
          <div className="w-full lg:w-[50%] md:flex mb-2 mr-2">
            <div className="w-full md:w-[50%] mr-2 mb-2">
              <div>Jenis Badan Usaha</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"Perseroan Terbatas (PT)"}
              />
            </div>
            <div className="w-full md:w-[50%] mb-2">
              <div>Tipe Kegiatan Bisnis</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"Pemilik Brand"}
              />
            </div>
          </div>
          <div className="w-full lg:w-[50%] mb-2">
            <div className="w-full md:flex w-full">
              <div className="w-full md:w-[50%]">
                <div>NPWP/KTP</div>
                <div className="flex">
                  <svg
                    viewBox="0 0 24 24"
                    style={{
                      width: "40px",
                      height: "40px",
                      overflow: "visible",
                      opacity: "1",
                      fill: "rgb(3, 3, 3)",
                    }}
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"></path>
                  </svg>
                  <button className="rounded-full w-[150px] bg-[#FF9500] ml-5">
                    Download
                  </button>
                </div>
              </div>
              <div className="w-full md:w-[50%]">
                <div>BPOM</div>
                <div className="flex">
                  <svg
                    viewBox="0 0 24 24"
                    style={{
                      width: "40px",
                      height: "40px",
                      overflow: "visible",
                      opacity: "1",
                      fill: "rgb(3, 3, 3)",
                    }}
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"></path>
                  </svg>
                  <button className="rounded-full w-[150px] bg-[#FF9500] ml-5">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex">
          <div className="w-full lg:w-[50%] md:flex mb-2 mr-2">
            <div className="w-full md:w-[50%] mr-2 mb-2">
              <div>Nama di NPWP / KTP</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"John"}
              />
            </div>
            <div className="w-full md:w-[50%] mb-2">
              <div>Nomor NPWP / KTP</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"123456789"}
              />
            </div>
          </div>
          <div className="w-full lg:w-[50%] mb-2">
            <div>Alamat di NPWP / KTP</div>
            <input
              type="text"
              value={""}
              className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
            />
          </div>
        </div>
        <div className="lg:flex">
          <div className="w-full lg:w-[50%] md:flex mr-2">
            <div className="w-full md:w-[50%] mr-2 mb-2">
              <div>Nama Produk</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"John"}
              />
            </div>
            <div className="w-full md:w-[50%] mb-2">
              <div>Nomor WhatsApp</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"123456789"}
              />
            </div>
          </div>
          <div className="w-full lg:w-[50%] md:flex">
            <div className="w-full md:w-[50%] mr-2 mb-2">
              <div>Nama Grup WhatsApp</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"John"}
              />
            </div>
            <div className="w-full md:w-[50%] mb-2">
              <div>Jenis Produk</div>
              <input
                type="text"
                className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
                value={"Fashion"}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex">
          <div className="w-full">
            <div>Website / URL Produk</div>
            <input
              type="text"
              className="w-full outline-none bg-gray-200 h-10 pl-2 pr-2"
              value={""}
            />
          </div>
        </div>
      </div>
      <div className="rounded-lg w-full mt-2">
        <div className="text-xl font-bold mb-3 ml-5">Daftar ID AD Account</div>
        <div className="bg-[#f9fcff] w-full p-5" style={{ boxShadow: "rgba(3, 3, 3, 0.1) 2px 0px 10px" }}>
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6">
            {
              accountList.map((account, index) => <div key={index}>{account}</div>)
            }
          </div>
        </div>
        <div className="flex justify-right p-2 mt-5">
        <button className="bg-[#3539FF] text-white h-8 mr-5 w-[100px] ml-auto pr-5 pl-5" style={{width: 'fit-content'}}>
          Add New Account
        </button>
      </div>
      </div>
      <div className="flex justify-right p-2 mt-2">
        <button className="bg-[#3539FF] text-white h-12 mr-5 w-[200px] ml-auto">
          EDIT/CONFIRM
        </button>
        <button
          className="bg-[#3539FF] text-white h-12 w-[200px]"
          onClick={handleBack}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default ClientView;
